import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ACTIVITY_TYPES } from 'state-domains/constants';

import { buildLoadRowReferencesUrl, buildLoadRowReferencesPointsUrl } from '../../../network/urls';
import { sendRetrieveRequest, convertToCamelWithSpecialParameters } from '../../../utils';
import { RowReference, RowReferences } from '../types';

export const loadRowReferences = (
    collar: string,
    tableView: string,
    actionName: string,
    processResultFunction: Function,
    type: ACTIVITY_TYPES,
): Observable<{
    result: RowReference[];
    actionName: string;
    processResultFunction: Function;
    type: ACTIVITY_TYPES;
}> => {
    const url =
        type === ACTIVITY_TYPES.DRILLING
            ? buildLoadRowReferencesUrl(collar, tableView)
            : buildLoadRowReferencesPointsUrl(collar, tableView);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<RowReferences>) => {
            const { RowReference } = response;
            const result = convertToCamelWithSpecialParameters<RowReference[]>(RowReference, {
                id: 'uid',
            });
            return observableOf({ result, actionName, processResultFunction, type });
        }),
    );
};
